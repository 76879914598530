/* ./styles/globals.css */
// Need in globals since we can't put css modules classes onto the google controlled DOM
@use '../components/google-translate.scss';
@use './sharing-button.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;


/* Needs to be in global scope  */


/* Custom Styles */
@layer components {
  .navbar-link {
    @apply px-3 py-2 flex items-center uppercase font-bold leading-snug text-gray-700 hover:opacity-75 ml-2;
  }
}

.header-link {
  @apply text-gray-700 hover:text-blue-500;
  text-decoration: none !important;
}

.mapboxgl-popup-close-button {
  padding: 1px 6px 1px 6px;
}

.mapboxgl-canvas:focus {
  outline: 0;
}
